const SEO = {
  defaultTitle: 'Optimus Inu',
  titleTemplate: '%s | Optimus Inu',
  description: 'Swap in our community driven ecosystem',
  openGraph: {
    type: 'website',
    url: 'https://optimus.io/',
    site_name: 'Optimus',
  },
  twitter: {
    handle: '@Optimus_Inu',
    site: '@Optimus_Inu',
    cardType: 'summary_large_image',
  },
}

export default SEO
