export interface Oracle {
  chainId: number
  address: string
  data: string
  name: string
}
// @ts-ignore
export abstract class Oracle implements Oracle {// @ts-ignore
  chainId// @ts-ignore
  address// @ts-ignore
  data// @ts-ignore
  name// @ts-ignore
  warning = ''// @ts-ignore
  error = ''
  // @ts-ignore TYPE NEEDS FIXING
  constructor(chainId: number, address: string, name: string, data: string) {
    this.chainId = chainId
    this.address = address
    this.data = data
    this.name = name
  }
}
