import Typography from 'app/components/Typography'
import { useActiveWeb3React } from 'app/services/web3'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import Container from '../Container'

const Footer = () => {
  const { chainId } = useActiveWeb3React()

  return (
    <div className="z-10 w-full">
      <Container maxWidth="7xl" className="px-6 pb-20 mx-auto">
        <div className="grid grid-cols-2 gap-10 pt-8 border-t md:grid-cols-3 lg:grid-cols-6 xs:px-6 border-primary">
          <div className="flex flex-col gap-1">
            <Link href="https://optimusinu.io/" passHref>
              <a target="_blank" rel="noreferrer">
              <div className="flex items-center justify-start gap-2 hover:cursor-pointer">
                <div className="">
                  <Image src="/images/logo.png" alt="Home" width="48px" height="48px" />
                </div>
                <div className="">
                  <Image src="/images/logoText.png" alt="Home" width="120px" height="30px" />
                </div>
              </div>
              </a>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
